import React from 'react'
import {  BsInstagram,  BsLinkedin,  BsWhatsapp } from "react-icons/bs"
import { FaFacebookF, } from "react-icons/fa"

const SocialMedia = () => {
  return (
    <div className='app__social'>
    <a href="https://www.linkedin.com/company/civiprime-engenharia/?viewAsMember=true">
        <div>
            <BsLinkedin />
        </div></a>
        <a href="https://www.facebook.com/profile.php?id=61551588562479">
        <div>
            <FaFacebookF />
        </div> </a>
        <a href="https://www.instagram.com/civiprime/">
        <div>
            <BsInstagram />
        </div></a>
        <a href="https://wa.me/244927974427">

        <div> 
        <BsWhatsapp/>
        </div></a>
    </div>
  )
}

export default SocialMedia