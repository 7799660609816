import React from 'react'
import { motion } from 'framer-motion';
import CiviprimeTituloBranco from '../../assets/CiviprimeTituloBranco.png'
import "./Header.scss"
import { useGlobalState } from "../../state"
import { AppWrap } from "../../wrapper"

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {

  const [lang] = useGlobalState("lang");

  return (
    <div className='app__header app__flex'>
      <div className='video-parent'>
        <video className='video-background' loop autoPlay muted>
          <source src="/Cut.mp4"type='video/mp4' />
        </video>
      </div>
      <motion.div
      whileInView={{ x: [ 0, 0 ], opacity: [0, 1] }}
      transition={{ duration: .5 }}
      className='app__header-info'      
      >
        <div className='app__header-badge'>
          
        </div>
      </motion.div>

      <motion.div
          
      >
        
        {lang === "PT" ? 
        <motion.div
        whileInView={{ x: [ -100, 0 ], opacity: [0, 1] }}
        transition={{ duration: .5 }}
        className='app__header-main'      
        >
          <img className='app__logoTapas' src={CiviprimeTituloBranco}/>          
          <p className='app__header-desc'>"O que tem de ser feito, merece ser bem feito"</p>
          <button className='app__button1' onClick={() => window.location.href = "#quem somos"}>Mais Informação</button>
          <button className='app__button2' onClick={() => window.location.href = "#contacto" }>Contacto</button>    
        
        </motion.div>

        ////english
        :
        <motion.div
        whileInView={{ x: [ -100, 0 ], opacity: [0, 1] }}
        transition={{ duration: .5 }}
        className='app__header-main'      
        >
          <img className='app__logoTapas' src={CiviprimeTituloBranco}/>          
          <p className='app__header-desc'>"What needs to be done, deserves to be well done"</p>
          <button className='app__button1' onClick={() => window.location.href = "#quem somos"}>More information</button>
          <button className='app__button2' onClick={() => window.location.href = "#contacto" }>Contact</button>    
        
        </motion.div>
      }
        
      </motion.div>

      <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className='app__header-circles'
      >
       
      </motion.div>
    </div>
  )
}

export default AppWrap(Header , "home");