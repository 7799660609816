import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalState } from "../../state"
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";
import CiviLogoAzul from "../../assets/CiviLogoAzul.png";


const Skills = () => {
  const [ lang ] = useGlobalState("lang");
  const [skills, setSkills] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Aeroporto");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

 

  function handleWorkFilter (item) {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(skills);
      } else {
        setFilterWork(skills.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  useEffect(() => {
    const skillsQuery = '*[_type == "skills"]';

    async function fetcher () {
      await client.fetch(skillsQuery).then((data) => {
        setSkills(data);
      });
    }
    
    fetcher();
    
   
  }, []);

  useEffect(() => {
    setActiveFilter("Buildings");
    setTimeout(() => {
    setFilterWork(skills.filter((work) => work.tags.includes("Buildings")));
  }, 500);
  }, [skills])
  
  
  


  return (
    <>
    {lang === "PT" ?
      <h2 className="head-text-skills" >A NOSSA EXPERIÊNCIA <img className="logo-about" style={{width:"45px"}} src={CiviLogoAzul} alt="logo"/></h2>
      :
      <h2 className="head-text-skills">OUR EXPERIENCE  <img className="logo-about" style={{width:"45px"}} src={CiviLogoAzul} alt="logo"/></h2>
       }
       <div className="line"></div>

      <div className="app__skills-container">
      {lang === "PT" && 
      <div className="work-filter">
        {["Obras Aeroportuárias", "Edificios", "Contenções Perifericas","Obras Hidráulicas","Obras Industriais","Obras Parques"].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`work-filter-item app__flex text-skills ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item}
            </div>
            
          )
        )}
      </div> }
      {/* ///// /English */}
      {lang === "EN" && 
      <div className="work-filter">
        {["Airport Works", "Buildings", "Peripheral Retaining","Hydraulic Works","Industrial Works","Parques Works"].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`work-filter-item app__flex text-skills ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item}
            </div>
          )
        )}
      </div> }

      
        <motion.div className="app__skills-list"
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0 }} >

      
        
          {filterWork            
            .map((skill, index) => (
              <motion.div
                animate={animateCard}
                whileInView={{ opacity: [0, 1], scale: [1.3, 1] }}
                transition={{ delay: 0 + index/10 }} 
                className="app__skills-item app__flex"
                key={skill.name}
              >
                <div className="app__skills-galery">
                <div class="img-gallery-container">
                  <img
                    className="img-galery"
                    src={urlFor(skill.imgUrl)}
                    alt="img"
                    draggable="false"
                  />
                </div>
                </div>
              </motion.div>
            ))}
        </motion.div>
    
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "experiência",
  "app__whitebg"
);
