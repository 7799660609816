import React, { useState, useEffect } from 'react'
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from "framer-motion";
import { setGlobalState, useGlobalState } from "../../state"
import "./Navbar.scss"
import CiviprimeAzul from "../../assets/CiviprimeAzul.png"
import CiviprimeMixColor from "../../assets/CiviprimeBejeBaixo.png"
import { BsInstagram, BsLinkedin, BsWhatsapp } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';

const Navbar = () => {

  const [toggle, setToggle] = useState(false);
  const [background, setBackground] = useState("linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(37,37,37,0) 100%)")
  const [textColor, setTextColor] = useState("white");
  const [ lang ] = useGlobalState("lang");


function handleLanguages() {
  if (lang === "PT") {
    setGlobalState("lang", "EN");
  } else {
    setGlobalState("lang", "PT");
  }
}

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 900) {
        setBackground("white");
        setTextColor("black"); // Set the desired text color here
      } else {
        setBackground("linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(37,37,37,0) 100%)"); // Reset the background when scrolling back
        setTextColor("white"); // Reset the text color when scrolling back
      }
    };

    window.addEventListener('scroll', handleScroll);
    const flagIcon = lang === 'PT' ? 'PT' : 'EN';
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="app__navbar" style={{background : background}}>

    <div className="app__navbar-logo">
      <img src={CiviprimeAzul} alt="logo" />

    </div>

    {lang === "PT" && 
    <ul className="app__navbar-links">
      {['home','quem somos', 'solucões', 'experiência', 'novidades', 'contacto'].map((item) => (
        <li className="app__flex p-text" key={`link-${item}`}>
          <div />
          <a style={{color: textColor}} href={`#${item}`}>{item}</a>
               

        </li>
      ))}
    </ul>    
    }
    {lang === "EN" && 
    <ul className="app__navbar-links">
      {['home','about us', 'solutions', 'experience', 'news', 'contact'].map((item) => (
        <li className="app__flex p-text" key={`link-${item}`}>
          <div />
          {item === "home" &&
          <a style={{color: textColor}} href={`#home`}>{item}</a>
          } 
          {item === "about us" &&
          <a style={{color: textColor}} href={`#quem somos`}>{item}</a>
          } 
          {item === "solutions" &&
          <a style={{color: textColor}} href={`#solucões`}>{item}</a>
          } 
          {item === "experience" &&
          <a style={{color: textColor}} href={`#experiência`}>{item}</a>
          } 
          {item === "news" &&
          <a style={{color: textColor}} href={`#novidades`}>{item}</a>
          } 
          {item === "contact" &&
          <a style={{color: textColor}} href={`#contacto`}>{item}</a>
          } 
         
        </li>
      ))}
      

    </ul>    
    }
    <button style={{width: "40px",  height: "40px",marginTop: "-20px"}} onClick={() => handleLanguages()}>{lang}</button>

    <div className="app__navbar-menu">
      <HiMenuAlt4 onClick={() => setToggle(true)} />

      {toggle && (
        <motion.div
          whileInView={{ x: [300, 0] }}
          transition={{ duration: 0.85, ease: 'easeOut' }}
        >
          <HiX onClick={() => setToggle(false)} />
          {lang === "PT"
          ?
          <ul>
         

                    <img className='civiprimeMixColor' src={CiviprimeMixColor} alt="civi" />
          {['home','quem somos', 'solucões', 'experiência', 'novidades', 'contacto'].map((item) => (
              <li key={item}>
                <a href={`#${item}`} onClick={() => setToggle(false)}>
                  {item}
                </a>

              </li>
              
            ))}
            <li style={{marginTop:"40px"}} className='social-footer'>
    <a  href="https://www.linkedin.com/company/civiprime-engenharia/?viewAsMember=true">
       
            <BsLinkedin className='socialLogo-footer'/>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61551588562479">
        
            <FaFacebook className='socialLogo-footer' />
         </a>
        <a href="https://www.instagram.com/civiprime/">
        
            <BsInstagram className='socialLogo-footer' />
        </a>
        <a href="https://wa.me/244927974427">

        
        <BsWhatsapp className='socialLogo-footer'/>
        </a>
    </li>
          </ul>
          
        :
        <ul>
          {['home','about us', 'solutions', 'experience', 'news', 'contact'].map((item) => (
              <li key={item}>
                {item === "home" && <a href={`#home`} onClick={() => setToggle(false)}>{item}</a>}
                {item === "about us" && <a href={`#quem somos`} onClick={() => setToggle(false)}>{item}</a>}
                {item === "solutions" && <a href={`#solucões`} onClick={() => setToggle(false)}>{item}</a>}
                {item === "experience" && <a href={`#experiência`} onClick={() => setToggle(false)}>{item}</a>}
                {item === "news" && <a href={`#novidades`} onClick={() => setToggle(false)}>{item}</a>}
                {item === "contact" && <a href={`#contacto`} onClick={() => setToggle(false)}>{item}</a>}
              </li>
              
            ))}
            
          </ul>
          
        }
        
        </motion.div>
      )}
    </div>
  </nav>
 );
};

export default Navbar