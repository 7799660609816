import React, { useState, useEffect } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";
import CiviLogoAzul from "../../assets/CiviLogoAzul.png";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Testimonial.scss";
import { useGlobalState } from "../../state";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [animateCard, setAnimateCard] = useState({ x: 0, opacity: 1 });
  const [lang] = useGlobalState("lang");
  const [showFullText, setShowFullText] = useState(false);
  const handleClick = (index) => {
    setCurrentIndex(index);
    setAnimateCard([{ x: 0, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ x: 0, opacity: 1 }]);
    }, 100);
  };
  const handleToggleText = () => {
    setShowFullText((prevShowFullText) => !prevShowFullText);
  };
  useEffect(() => {
    const query = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);
  const isMobile = window.innerWidth <= 1550;
  return (
    <div id="app__testimonial">
      {testimonials.length && (
        <>
          {lang === "PT" ? (
            <h1 className="app__testimonials-title"><img className="logo-about"style={{width:"45px"}} src={CiviLogoAzul} alt="logo"/>NOTICIAS</h1>
          ) : (
            <h1 className="app__testimonials-title">NEWS</h1>
          )}

          <div className="line-testimonials"></div>
          <div className="testimonial-parent">
            <div className="img-parent-testimonial">
              <img
                className="img-testimonial"
                src={urlFor(testimonials[currentIndex].imageurl)}
                alt={testimonials[currentIndex].name}
              />
            </div>
            <motion.div
              className="testimonial-desc"
              transition={{ duration: 0.2 }}
              animate={animateCard}
            >
              <h1>{testimonials[currentIndex].name}</h1>
              <h5>Data:{testimonials[currentIndex].company}</h5>
              <p>
                {isMobile && testimonials[currentIndex].feedback.length > 100
                  ? showFullText
                    ? testimonials[currentIndex].feedback
                    : testimonials[currentIndex].feedback.substring(0, 100)
                  : testimonials[currentIndex].feedback}
              </p>
              
              {isMobile &&
                testimonials[currentIndex].feedback.length > 100 && (
                  <button onClick={handleToggleText}>
                    {showFullText ? "Read less" : "Read more"}
                  </button>
                )}
            </motion.div>
          </div>

          <div className="app__testimonial-btns app__flex">
            <div
              className="button-testimonial"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>

            <div
              className="button-testimonial"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "novidades",
  "app__primarybg"
);
