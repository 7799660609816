import React, { useEffect, useState } from "react";
import "./Footer.scss";

import { AppWrap, MotionWrap } from "../../wrapper";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineSupportAgent } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useGlobalState } from "../../state";
import { client } from "../../client";
import {
  FaFacebook,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from "react-icons/fa";
import { BsInstagram, BsLinkedin, BsWhatsapp } from "react-icons/bs";

const Footer = () => {
  const [lang] = useGlobalState("lang");
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    // Simulate the map loading process (replace with actual map loading logic)
    setTimeout(() => {
      setIsMapLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState("none");

  const { username, email, message } = formData;
  const handleContact = () => {
    if (display === "none") {
      setDisplay("flex");
      setIsContactOpen(true);
    } else {
      setDisplay("none");
      setIsContactOpen(false);
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log("sss",err));
  };

  const footerData = {
    PT: [
      { location: "LOCALIZAÇÃO" },
      { headquarters: "Sede" },
      { schedule: "Hórario" },
      { contact: "Contactos" },
    ],
    EN: [
      { location: "LOCATION" },
      { headquarters: "Headquarters" },
      { schedule: "Schedule" },
      { contact: "Contacts" },
    ],
  };

  return (
    <div className="page">
      <p className="local">{footerData[lang][0].location}</p>

      <div className="map-iframe">
        {isMapLoading ? (
          <div className="loading-indicator">Loading Map...</div>
        ) : (
          <iframe
            title="Civiprime"
            width="100%"
            height="500"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=Rua%20Jo%C3%A3o%20Seca%20n.%C2%BA20A%20Luanda,%20Angola+(Civiprime)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        )}
      </div>
      <div className="footer-container">
        {lang === "PT" ? (
          <div className="parent-contact">
            <div className="footer-left" style={{ display: display }}>
              <button onClick={handleContact} type="button" class="btn-close">
                <span class="icon-cross"></span>
                <span class="visually-hidden">
                  {isContactOpen ? "" : "converse connosco"}
                </span>
              </button>

              <h2 className="head-text-footer">Contacto</h2>
              <div className="app__footer-cards">
                <div className="app__footer-card ">
                  {/* <img src={images.email} alt="email" /> */}
                  <a
                    href="mailto:geral@civiprime.com"
                    className="p-text button-contact"
                  >
                    geral@civiprime.com
                  </a>
                </div>
                <div className="app__footer-card">
                  {/* <img src={images.mobile} alt="phone" /> */}
                  <a
                    href="tel: +244 927974427"
                    className="p-text button-contact"
                  >
                    +244 927974427
                  </a>
                </div>
              </div>
              {!isFormSubmitted ? (
                <div className="app__footer-form app__flex">
                  <div className="app__flex">
                    <input
                      className="p-text normal-text"
                      type="text"
                      placeholder="O Seu Nome"
                      name="username"
                      value={username}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="app__flex">
                    <input
                      className="p-text normal-text"
                      type="email"
                      placeholder="Seu Email"
                      name="email"
                      value={email}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div>
                    <textarea
                      className="p-text normal-text"
                      placeholder="Sua Mensagem"
                      value={message}
                      name="message"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <button
                    type="button"
                    className="p-text"
                    onClick={handleSubmit}
                  >
                    {!loading ? "Send Message" : "Sending..."}
                  </button>
                </div>
              ) : (
                <div>
                  <h3 className="head-text">Thank you for getting in touch!</h3>
                </div>
              )}
            </div>

            <p className="map-desc">
              O seu empreendimento <br />
              <br /> será o nosso próximo sucesso
              <div className="buttonParent">
                <button onClick={() => handleContact()} className="map-button">
                  <div className="chat-button-parent">
                    <span className="left">
                      <FaLongArrowAltLeft />
                    </span>
                    <h4 className="animated-text">CONVERSE CONNOSCO</h4>
                    <span className="right">
                      <FaLongArrowAltRight />{" "}
                    </span>
                  </div>
                </button>
                <div className="line-footer"></div>
              </div>
            </p>
          </div>
        ) : (
          <div className="parent-contact">
            <div className="footer-left" style={{ display: display }}>
              <button onClick={handleContact} type="button" class="btn-close">
                <span class="icon-cross"></span>
                <span class="visually-hidden">
                  {isContactOpen ? "" : "converse connosco"}
                </span>
              </button>
              <h2 className="head-text-footer">Contact</h2>
              <div className="app__footer-cards">
                <div className="app__footer-card ">
                  {/* <img src={images.email} alt="email" /> */}
                  <a
                    href="mailto:hello@micael.com"
                    className="p-text button-contact"
                  >
                    geral@civiprime.com
                  </a>
                </div>
                <div className="app__footer-card">
                  {/* <img src={images.mobile} alt="phone" /> */}
                  <a
                    href="tel:+1 (123) 456-7890"
                    className="p-text button-contact"
                  >
                    +244 927974427
                  </a>
                </div>
              </div>
              {!isFormSubmitted ? (
                <div className="app__footer-form app__flex">
                  <div className="app__flex">
                    <input
                      className="p-text normal-text"
                      type="text"
                      placeholder="Your Name"
                      name="username"
                      value={username}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="app__flex">
                    <input
                      className="p-text normal-text"
                      type="email"
                      placeholder="Your Email"
                      name="email"
                      value={email}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div>
                    <textarea
                      className="p-text normal-text"
                      placeholder="Your message"
                      value={message}
                      name="message"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <button
                    type="button"
                    className="p-text"
                    onClick={handleSubmit}
                  >
                    {!loading ? "Send Message" : "Sending..."}
                  </button>
                </div>
              ) : (
                <div>
                  <h3 className="head-text">Thank you for getting in touch!</h3>
                </div>
              )}
            </div>

            <p className="map-desc">
              <span className="custom-o">Your </span>venture
              <br />
              <br />
              will be our next success.
              <div className="buttonParent">
                <button onClick={() => handleContact()} className="map-button">
                  <div className="chat-button-parent">
                    <span className="left">
                      <FaLongArrowAltLeft />
                    </span>
                    <h4 className="animated-text">CHAT WITH US</h4>
                    <span className="right">
                      <FaLongArrowAltRight />{" "}
                    </span>
                  </div>
                </button>
                <div className="line-footer"></div>
              </div>
            </p>
          </div>
        )}

        <div className="parent-bottom">
          <div className="child-bottom">
            <div className="parent-bottom-2">
              <div className="icon-row">
                <CiLocationOn className="icons-footer" />
                <h1 className="bottom-title-1">
                  {footerData[lang][1].headquarters}
                </h1>
              </div>
              <p className="bottom-desc-1">Rua João Seca n.º20A </p>
              <p className="bottom-desc-2">Maianga</p>
              <p className="bottom-desc-3">Luanda,Angola</p>
            </div>

            <div className="parent-bottom-2">
              <div className="icon-row">
                <AiOutlineClockCircle className="icons-footer" />
                <h1 className="bottom-title-2">
                  {footerData[lang][2].schedule}
                </h1>
              </div>
              <p className="bottom-desc-4">Segunda a Sexta / 9h-18h</p>
              <p className="bottom-desc-5">Instagram</p>
              <p className="bottom-desc-5">Facebook</p>
            </div>

            <div className="parent-bottom-2">
              <div className="icon-row">
                <MdOutlineSupportAgent className="icons-footer" />
                <h1 className="bottom-title-1">
                  {footerData[lang][3].contact}
                </h1>
              </div>

              <a href="tel: +244 927974427">
                <p className="bottom-desc-1">+244 927 974 427</p>
              </a>
              <a href="mailto:geral@civiprime.com">
                <p className="bottom-desc-3">geral@civiprime.com</p>
              </a>
              <a href="https://wa.me/244927974427">
                <p className="bottom-desc-7">Whatsapp</p>
              </a>
            </div>
          </div>
        </div>
        <div className="social-footer">
          <a href="https://www.linkedin.com/company/civiprime-engenharia/?viewAsMember=true">
            <div>
              <BsLinkedin className="socialLogo-footer" />
            </div>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61551588562479">
            <div>
              <FaFacebook className="socialLogo-footer" />
            </div>{" "}
          </a>
          <a href="https://www.instagram.com/civiprime/">
            <div>
              <BsInstagram className="socialLogo-footer" />
            </div>
          </a>
          <a href="https://wa.me/244927974427">
            <div>
              <BsWhatsapp className="socialLogo-footer" />
            </div>
          </a>
        </div>
        <div className="copyright-container">
          <p className="copyright2">
            © Civiprime Copyright 2023 Todos os direitos reservados.
            <span> POWERED BY ArcaneWeb Studios </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Footer, "app__footer"), "contacto");
