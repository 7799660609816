import React, { useEffect, useState } from "react";
import Caroussel1 from "../../assets/caroussel1.jpg";
import Caroussel2 from "../../assets/caroussel2.jpg";
import Caroussel3 from "../../assets/caroussel3.jpg";
import Grua from "../../assets/grua.png";
import { AppWrap } from "../../wrapper";
import "./About.scss";
import { useGlobalState } from "../../state";
import { ImArrowRight } from "react-icons/im";
import { ImArrowLeft } from "react-icons/im";
import CiviLogoAzul from "../../assets/CiviLogoAzul.png";
import { FaLongArrowAltRight } from "react-icons/fa";

const SectionOne = () => {
  const images = [Caroussel1, Caroussel2, Caroussel3];
  const [lang] = useGlobalState("lang");
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    const newSlide = (currentSlide + 1) % images.length;
    setCurrentSlide(newSlide);
  };

  const prevSlide = () => {
    const newSlide = (currentSlide - 1 + images.length) % images.length;
    setCurrentSlide(newSlide);
  };
  const prevIndex = (currentSlide - 1 + images.length) % images.length;
  const nextIndex = (currentSlide + 1) % images.length;


  const textData = {
    PT: [
      {
        title: "MISSÃO",
        content:
          "Executar os nossos serviços com total competência e dedicação, de modo a criar parcerias de excelência e, sobretudo, satisfazer as necessidades e expectativas dos nossos clientes.",
      },
      {
        title: "VALORES",
        content: `Lealdade, atribui-nos ligações fortes e com capacidade de resistir a ambientes sinuosos e com aptidão de responder à constante e inesperada mudança. Os nossos vínculos de confiança são capazes de desenvolver experiências com um toque humano espontâneo e diferenciador.
           
           Responsabilidade económica, social e ambiental, são os nossos suportes para uma estrutura com um comprometimento global. 

           Inovação, visando a realização de objetivos dos nossos clientes.
           
           Excelência na satisfação de clientes, parceiros e colaboradores.`,
      },
      {
        title: "VISÃO",
        content:
          "Crescer de forma sustentável, seguindo políticas de qualidade na prestação dos diversos serviços no âmbito da engenharia e construção, tornando-se referência no mercado angolano e internacional, através do cumprimento rigoroso dos padrões profissionais da qual refletirá nossa total dedicação.",
      },
    ],
    EN: [
      {
        title: "MISSION",
        content:
          "To perform our services with complete competence and dedication, aiming to establish partnerships of excellence and, above all, to meet the needs and expectations of our clients.",
      },
      {
        title: "VALUES",
        content: `Loyalty grants us strong connections capable of withstanding complex environments and adept at responding to constant and unexpected change. Our bonds of trust can foster experiences with a spontaneous and distinctive human touch. 
          
         Economic, social, and environmental responsibility form the foundations of our commitment to a comprehensive framework. 
          
          Innovation, aimed at achieving our clients' objectives. 
          
          Excellence in satisfying clients, partners, and collaborators.`,
      },
      {
        title: "VISION",
        content:
          "To grow sustainably, following quality policies in the provision of various engineering and construction services, becoming a reference in the Angolan and international market, through strict compliance with professional standards, which will reflect our total dedication.",
      },
    ],
  };


  return (
    <>
      {lang === "PT" ? (
        <div className="about-parent parent-top">
          <img className="img-gruas" src={Grua} alt="grua" />
          <div className="about-desc about-desc-one">
            <div className="slogan">"INOVAÇÃO NO SECTOR DE CONSTRUÇÃO"</div>

            <h1 className="title-one">DESCUBRA A CIVIPRIME <img className="logo-about"style={{width:"45px"}} src={CiviLogoAzul} alt="logo"/> </h1>
           
            <div className="line2"></div>
            <p>
             Nós somos uma Empresa que atua no ramo da Engenharia e
              Construção, que aposta na Concepção e execução de projetos, com
              objetivo de atingir uma posição de liderança no mercado Angolano,
              sempre com uma lealdade grande para com os seus parceiros, sejam
              clientes ou colaboradores, em responsabilidade para com todos os
              intervenientes, garantindo inovação nas soluções apresentadas e um
              trabalho de excelência.
            </p>
            <br />
            <p>
              Fundada em 2023, é gerida por profissionais com uma vasta
              experiência no ramo da Engenharia, nos mercados Europeus e
              Africanos.
            </p>
         <a href="#solucões">
            <button class="button-about">
              <span class="button-text">EXPLORE OS NOSSOS SERVIÇOS  <FaLongArrowAltRight /></span>
              
            </button></a> 
          </div>
        </div>
      ) : (
        <div className="about-parent parent-top">
          <img className="img-gruas" src={Grua} alt="grua"/>
          <div className="about-desc about-desc-one">
            <div className="slogan">INNOVATION IN THE CONSTRUCTION SECTOR</div>
            
            <h1 className="title-one">DISCOVER CIVIPRIME<img style={{width:"45px"}} src={CiviLogoAzul} alt="logo"/></h1>
           
            <div className="line2"></div>
            
            <p>
              We are an company that operates in the Engineering and
              Construction sector, focusing on the design and execution of
              projects, with the goal of achieving a leadership position in the
              Angolan market. The company maintains a strong loyalty to its
              partners, whether they are clients or collaborators, taking
              responsibility towards all stakeholders. It ensures innovation in
              the solutions presented and a commitment to excellent work.
            </p>
            <br />
            <p>
              Founded in 2023, it is managed by professionals with extensive
              experience in the field of Engineering, within both European and
              African markets.
            </p>
            <a href="#solucões">

            <button class="button-about">
              <span class="button-text">EXPLORE OUR SERVICES<FaLongArrowAltRight /> </span>
              
                       
            </button></a>
          </div>
          
        </div>
      )}

      <div className="carousel-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-slide slide-${currentSlide}`} // Assign unique class to each slide
            style={{
              backgroundImage: `url(${images[currentSlide]})`, // Use currentSlide to select the image
            }}
          >
            <div className="content-parent">
              <div className="title">{textData[lang][currentSlide].title}</div>
              <div className="line-vision"></div>
              <div className="content">
                {textData[lang][currentSlide].content}
              </div>
            </div>
          </div>
        ))}
        <div className="carousel-button-parent">
          <button
            className="carousel-button1 carousel-prev"
            onClick={prevSlide}
          >
            <div className="arrowsflexRight">
              {textData[lang][prevIndex].title}
              <ImArrowLeft className="arrow" />
            </div>
          </button>
          <button
            className="carousel-button2 carousel-next"
            onClick={nextSlide}
          >
            <div className="arrowsflex">
              {textData[lang][nextIndex].title}
              <ImArrowRight className="arrow" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default AppWrap(SectionOne, "quem somos");
