import React, { useState, useEffect } from "react";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Work.scss";

import HouseSchema from "../../assets/houseScheme.png";
import { PiHammerLight, PiProjectorScreenChartLight } from "react-icons/pi";
import { TbBuildingCommunity } from "react-icons/tb";
import { TfiRulerPencil } from "react-icons/tfi";
import { useGlobalState } from "../../state";
import CiviLogoAzul from "../../assets/CiviLogoAzul.png";
import { FaLongArrowAltRight } from "react-icons/fa";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  const [lang] = useGlobalState("lang");
  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  return (
    <>
      {lang === "PT" ? (
        <div className="work-container">
          <div className="text-container-child">
            <div className="text">
              <div className="slogan-work">
                "DO CONCRETO AO SONHO, TORNAMOS REALIDADE"
              </div>

              <h1 className="title-one">
                <img className="logo-about" style={{ width: "45px" }} src={CiviLogoAzul} alt="logo" />
                CONSTRUÍMOS EXPERIÊNCIAS
              </h1>

              <div className="line1"></div>

              <p>
                Acreditamos que os edifícios não devem ser apenas ocupados: eles
                devem ser vivenciados. Selecionamos cuidadosamente todos os
                nossos materiais e elaboramos todos os nossos projetos para
                construir estruturas ao mais alto nivel. A nossa equipa experiente
                de profissionais toma decisões criteriosas a cada passo do
                caminho para garantir que permaneçamos dentro do orçamento e
                respondamos rapidamente às necessidades em constante evolução do
                projeto.
              </p>
            </div>
            <img src={HouseSchema} alt="background" className="house-img" />
          </div>
          <div className="services-parent">
            <h2 className="services-title">Conheça os nossos serviços</h2>
            <div className="services-row">
              <div className="services-desc">
                <PiProjectorScreenChartLight className="icons-works" />
                <span>Projetos de Concepção e Execução</span>
                <p className="exemplos">
                  Projetos arquitetônicos residenciais, de planeamento urbano
                  comerciais, como lojas e escritórios, projetos de paisagismo.
                  Projetos industriais e de instalações. Projetos de restauração
                  de edifícios históricos. Projetos de instalações de saúde,
                  como hospitais e outros diversos  projetos.
                </p>
              </div>
              <div className="services-desc">
                <PiHammerLight className="icons-works" />
                <span>Construção Civil </span>
                <p className="exemplos">
                Construção de edifícios residenciais.
Construção de edifícios comerciais.
Construção de estradas e rodovias.
Construção de pontes.
Construção de túneis.
Construção de instalações industriais.
Construção de instalações esportivas.
E várias outras construções.
                </p>
              </div>

              <div className="services-desc">
                <TfiRulerPencil className="icons-works" />
                <span>Ampliações e remodelações</span>
                <p className="exemplos">
                Renovação de espaços interiores ou exteriores.
Expansão de Edificios publicos ou privados.
Novas divisões em edifícios existentes.
Remodelação de espaços comerciais para atender a novos requisitos.
Reabilitação de edifícios antigos para novos usos.
Melhorias em edifícios históricos, mantendo sua integridade.
Expansão e atualização de escritórios corporativos.
                </p>
              </div>

              <div className="services-desc">
                <TbBuildingCommunity className="icons-works" />
                <span>Infraestruturas</span>
                <p className="exemplos">
                Construção de redes de esgoto.
Desenvolvimento de sistemas de água potável.
Construção de redes elétricas e subestações.
Construção de sistemas de transporte público
Construção de sistemas de drenagem de águas pluviais.
Entre outros...
                </p>
              </div>
             
            </div>
            <a href="#contacto">
                <button className="orcamento">
                  Solicite Orçamento <FaLongArrowAltRight />
                  
                </button>
              </a>
            <div>
         
            </div>
          </div>
        </div>
      ) : (
        ////English
        <div className="work-container">
          <div className="text-container-child">
            <div className="text">
              <div className="slogan-work">
                "FROM CONCRETE TO DREAM, WE MAKE REALITY."
              </div>

              <h1>
                <img style={{ width: "45px" }} src={CiviLogoAzul} alt="logo" />
                WE BUILD EXPERIENCES
              </h1>

              <div className="line1"></div>

              <p >
                We believe that buildings should not just be occupied; they
                should be experienced. We carefully select all our materials and
                craft all our projects to build structures at the highest level.
                Our experienced team of professionals makes thoughtful decisions
                every step of the way to ensure that we stay within budget and
                respond quickly to the ever-evolving project needs.
              </p>
            </div>
            <img src={HouseSchema} alt="background" className="house-img" />
          </div>
          <div className="services-parent">
            <h2 className="services-title">Discover our services</h2>
            <div className="services-row">
              <div className="services-desc">
                <PiProjectorScreenChartLight className="icons-works" />
                <span>Design and Execution Projects</span>
                <p className="exemplos">
                  Projetos arquitetônicos residenciais, de planejamento urbano.
                  comerciais, como lojas e escritórios. Projetos de paisagismo.
                  Projetos industriais e de instalações. Projetos de restauração
                  de edifícios históricos. Projetos de instalações de saúde,
                  como hospitais e clínicas
                </p>
              </div>
              <div className="services-desc">
                <PiHammerLight className="icons-works" />
                <span>Civil Construction </span>
                <p className="exemplos">
                Construction of residential buildings.
Construction of commercial buildings.
Construction of roads and highways.
Construction of bridges.
Construction of tunnels.
Construction of industrial facilities.
Construction of sports facilities.
And various other construction
                </p>
              </div>

              <div className="services-desc">
                <TfiRulerPencil className="icons-works" />
                <span>Extensions and Remodeling</span>
                <p className="exemplos">
                Renovation of interior or exterior spaces.
Expansion of public or private buildings.
Creation of new divisions within existing buildings.
Remodeling of commercial spaces to meet new requirements.
Rehabilitation of old buildings for new purposes.
Enhancements to historic buildings while preserving their integrity.
Expansion and modernization of corporate offices.
                </p>
              </div>

              <div className="services-desc">
                <TbBuildingCommunity className="icons-works" />
                <span>Infrastructure</span>
                <p className="exemplos">
                
Construction of sewage networks.
Development of potable water systems.
Construction of electrical grids and substations.
Construction of public transportation systems.
Construction of stormwater drainage systems.
Among others...
                </p>
              </div>
            </div>
            <div>
              <a href="#contacto">
                <button className="orcamento">
                Request a Quote  <FaLongArrowAltRight />
                  
                </button>
              </a>
            </div>
          </div>
        </div>
        /* <div className="text-container">
          <h2 className="head-text-works">
            <span>We Build Experiences</span>WE BUILD EXPERIENCES
          </h2>
          <p>
            We believe that buildings should not only be occupied: they should
            be experienced. We carefully select all our materials and craft all
            our projects to construct structures at the highest level. Our
            experienced team of professionals makes thoughtful decisions at
            every step of the way to ensure we stay within budget and respond
            swiftly to the ever-evolving project needs.
          </p>
          <div className="services-parent">
            <h2 className="services-title">Services</h2>
            <div className="services-desc">
              <PiProjectorScreenChartLight className="icons-works" />
              <span>Design and Execution Projects</span>
            </div>
            <div className="services-desc">
              <PiHammerLight className="icons-works" />
              <span>Civil Construction </span>
            </div>
            <div className="services-desc">
              <TfiRulerPencil className="icons-works" />
              <span>Extensions and Remodeling</span>
            </div>
            <div className="services-desc">
              <TbBuildingCommunity className="icons-works" />
              <span>Infrastructure</span>
            </div>
            <a href="#contacto">
              <button className="button-orcamento">Request a Quote</button> 
            </a>
          </div>
        </div> */
      )}
    </>
  );
};

export default AppWrap(MotionWrap(Work, "app__works"), "solucões");
